<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :target="href && href !== '#' ? '_blank' : undefined"
    :to="item.to"
    v-if="checkListPermission(item.title)"
    :title="item.title"
  >
    <v-list-item-icon v-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>

    <v-list-item-action v-if="item.title == 'Approval' && mission > 0">
      <v-chip color="red" small dark>{{ mission }}</v-chip>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import Axios from "@/config/backend-service";
import Themeable from "vuetify/lib/mixins/themeable";
import { mapState } from "vuex";

export default {
  name: "BaseItem",

  mixins: [Themeable],

  props: {
    item: {
      type: Object,
      default: () => ({
        href: undefined,
        icon: undefined,
        title: undefined,
        to: undefined,
      }),
    },
    text: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mission: 0,
    permission: false,
  }),

  computed: {
    ...mapState("login", ["admin_profile"]),

    href() {
      return this.item.href || (!this.item.to ? "#" : undefined);
    },
  },
  created() {
    setTimeout(() => {
      this.mission = JSON.parse(localStorage.getItem("noti")).activity;
    }, 100);
    this.checkPermission();

  },
  methods: {
    checkPermission() {
      Axios.get("/admin/partner/getPermissionPartner").then((res) => {
        this.permission = res.data.data;
      });
    },
    checkListPermission(title) {
      if (title == "Reward Partner") {
        return this.permission;
      } else if (
        title == "OTP Usage" ||
        this.admin_profile.brand_trees_id == null
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
